import _types from "../types";
import _helper from "../helper";
import _ActionSet from "../ActionSet";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = exports && exports.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.close = exports.show = exports.Share = exports.Action = void 0;
var types_1 = _types;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
/**
 * Types
 */
/**
 * @public
 */
var Action;
(function (Action) {
  Action["SHOW"] = "APP::SHARE::SHOW";
  Action["CLOSE"] = "APP::SHARE::CLOSE";
})(Action = exports.Action || (exports.Action = {}));
/**
 * Action
 */
/**
 * A set of actions for displaying a Share Sheet component
 * @public
 */
var Share = /** @class */function (_super) {
  __extends(Share, _super);
  function Share(app) {
    return _super.call(this, app, types_1.Group.Share, types_1.Group.Share) || this;
  }
  Share.prototype.dispatch = function (action, payload) {
    switch (action) {
      case Action.SHOW:
        this.dispatchShareAction(Action.SHOW, payload);
        break;
      case Action.CLOSE:
        this.dispatchShareAction(Action.CLOSE, payload);
        break;
      default:
        throw new Error("Action: " + action + " not supported");
    }
    return this;
  };
  /**
   * @internal
   */
  Share.prototype.dispatchShareAction = function (actionType, payload) {
    this.app.dispatch(helper_1.actionWrapper({
      type: actionType,
      group: types_1.Group.Share,
      payload: __assign({
        id: this.id
      }, payload)
    }));
  };
  return Share;
}(ActionSet_1.ActionSet);
exports.Share = Share;
function show() {
  return helper_1.actionWrapper({
    group: types_1.Group.Share,
    type: Action.SHOW
  });
}
exports.show = show;
function close(payload) {
  return helper_1.actionWrapper({
    group: types_1.Group.Share,
    type: Action.CLOSE,
    payload: payload
  });
}
exports.close = close;
export default exports;
export const __esModule = exports.__esModule;
const _close = exports.close,
  _show = exports.show,
  _Share = exports.Share,
  _Action = exports.Action;
export { _close as close, _show as show, _Share as Share, _Action as Action };